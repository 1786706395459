/* eslint indent: "off" */

export default [
  {'size': '1',
  'type': 'pic',
  'source': 'instagram',
  'url': 'https://www.instagram.com/p/BiKCzDMAyyt',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'id': 569911371,
  'title': 'Nagz - Propaganda [2019]',
  'text': 'Demobit2019 tied Music compo winner // rough kuduro with c64 samples',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/propaganda',
  'trackId': '569911371',
  'cover': 'https://i1.sndcdn.com/artworks-000483005166-abb4xk-t500x500.jpg',
  'sticky': false,
  'hidden': false},
  
  {'size': '3',
  'title': 'Nagz - Heatwave [2018]',
  'text': 'Function2018 Music compo winner // Acapellas by Cut Back, Hawkeye, Krafty Kuts, etc. // Img courtesy of ES',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagzheatwave-2018-free-dl',
  'trackId': '497401710',
  'cover': 'https://i1.sndcdn.com/artworks-000402426900-pjf55q-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'The Adjective - Volna [2018]',
  'text': 'QBParty2018 entry // Gargaj, Immortal Rat & Nagz // Song featured on Kahvi Collective in One Synth EP',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=H8vEX1ddFak',
  'videoId': 'nn3NpHahilY',
  'sticky': false,
  'hidden': false},
  
  {'size': '3',
  'title': 'Makunouchi Bento - Pine Quiz (Nagz Remix) [2018]',
  'text': 'Official Makunouchi Bento remix // Footage from Bodrog',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=6kkUK65lxas',
  'videoId': '6kkUK65lxas',
  'sticky': false,
  'hidden': false},
  
  {'size': '4',
  'title': 'Nagz - Chips Don\'t Lie LP [2010]',
  'text': 'Old chiptunes remastered // Available on all major shops and services',
  'type': 'web',
  'source': 'custom',
  'url': 'https://fanlink.to/ngzchp',
  'cover': 'img/alb_cdl.png',
  'sticky': false,
  'hidden': false},
  
  {'size': '3',
  'title': 'TGD - Castaway [2018]',
  'text': 'QBParty2018 #2 demo // Code & visual by Jimmi, music by Nagz',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=b6f5KFb2jrI',
  'videoId': 'b6f5KFb2jrI',
  'sticky': false,
  'hidden': false},
  
  {'size': '2',
  'title': 'Greenroom & Erydium - Konfr0ntzone [2018]',
  'text': 'QBParty2018 demo // Music by Nagz, rest by Pasy, Aha & Zahar',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=kDPlPN0tK70',
  'videoId': 'kDPlPN0tK70',
  'sticky': false,
  'hidden': false},
  
  {'size': '2',
  'title': 'Nagz - Absolute Unit [2018]',
  'text': 'Last place at QBParty // Beenie Man & Don Diablo included // Horrible mixout sorry',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/absolute-unit',
  'trackId': '443440512',
  'cover': 'https://i1.sndcdn.com/artworks-000347762442-zgqgmq-t500x500.jpg',
  'sticky': false,
  'hidden': false},
  
  {'size': '3',
  'title': 'Nagz - Lethal [2018]',
  'text': 'Demobit2018 Music compo winner',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-lethal-2018',
  'trackId': '394400355',
  'cover': 'https://i1.sndcdn.com/artworks-000297273057-t8pdih-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Surfalone - Soul Machine (Nagz remix) [2017]',
  'text': 'Official remix // Out on Selected Sounds',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/selected-sounds/surfalone-soul-machine-nagz',
  'trackId': '345458881',
  'cover': 'https://i1.sndcdn.com/artworks-000245696622-1nesq6-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'ROHU - 800M [2017]',
  'text': 'Arrangement by Nagz // Base samples (mostly) by Makunouchi Bento (Waka-X & Qza), Selfmademusic, Kcg & Kitty Kye',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-rohu-2017',
  'trackId': '339039150',
  'cover': 'https://i1.sndcdn.com/artworks-000239430644-cj4hud-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Nagz - Form Over Function [2017]',
  'text': 'Contains my 2016 track \'Lean Hopper\'',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=zvDS3vsRx34',
  'videoId': 'zvDS3vsRx34',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Walking [2017]',
  'text': 'Old tune finished later // Used in Greetings by HarDread',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-walking-2017',
  'trackId': '341768224',
  'cover': 'https://i1.sndcdn.com/artworks-000242054381-yvur6g-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Nagz - Told you So [2014]',
  'text': 'Directed by Holdosi Máté, edited by Vecsei Viktor',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=2PnFVxc3zNI',
  'videoId': '2PnFVxc3zNI',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Feeling How - Burden of Proof [2013]',
  'text': 'Arrangement by Nagz // Vocals by Ekrah, Sarkanz on violin // mixout in 2017',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-burden-of-proof-feat-ekrah-sarkanz',
  'trackId': '341733638',
  'cover': 'https://i1.sndcdn.com/artworks-000242027261-z9wubw-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Now What? [2014]',
  'text': 'Function 2014 wild demo competition #12',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=TXLZBLMEWuo',
  'videoId': 'TXLZBLMEWuo',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Ducks And Cubes [2017]',
  'text': 'Soundtrack for \'Ducks and Cubes\' by TGD // code by Jimmi',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-ducks-and-cubes-2017',
  'trackId': '322570630',
  'cover': 'https://i1.sndcdn.com/artworks-000222543759-71ccdr-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz feat. Jeroen Tel (WAVE) & KcG - When I See You [2017]',
  'text': 'Bass, arrangement & mixing: Nagz, acoustic guitar: KcG, vocals: Jeroen Tel // QBParty 2017 music competition #3',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-kcg-jeroen-tel-when-i-see-you',
  'trackId': '324764399',
  'cover': 'https://i1.sndcdn.com/artworks-000224618576-bbvsbt-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Frame Break by Jung Eun Jang & Je Yeon Han',
  'text': 'Music by Dávid Halmi as Bassrack Wobama',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/222007272',
  'cover': 'https://i.vimeocdn.com/video/640425309_640x360.jpg',
  'videoId': '222007272',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Zagar - Dream of a Machine (Nagz Remix) [2014]',
  'text': 'Official remix',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/zagar-dream-of-a-machine-nagz-remix-2014',
  'trackId': '264283608',
  'cover': 'https://i1.sndcdn.com/artworks-000162975327-4osatw-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'GAME // Baby Mummy\'s Curse',
  'text': 'By Mazeon // music by Nagz',
  'type': 'web',
  'source': 'custom',
  'url': 'http://gamejolt.com/games/baby-mummy-s-curse/16394/',
  'cover': 'img/bmc.png',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Torus Pushers [2014]',
  'text': 'Function 2014 music competition #7 // contains lead melody from Second Reality by Purple Motion & sampled material from Moleman 2',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/torus-pushers-2014',
  'trackId': '170718260',
  'cover': 'https://i1.sndcdn.com/artworks-000092998590-4992bq-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'TGD - The Second Coming of the Csillagtök [2017]',
  'text': 'Function 2017 demo competition #2 // code by Jimmi, cunning vegetable digitalization by Maugli, music by Nagz',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=fZ0e1HUBJQs',
  'videoId': 'F1oSxz8OjN4',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Imagine Invits [2018]',
  'text': 'Demobit2018 Wild demo compo #5 // We ALMOST made the invit',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=XI29sO3qlcg',
  'videoId': 'XI29sO3qlcg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Shiny Pixel Town [2017]',
  'text': 'Started in 2007',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-shiny-pixel-town-2007-2017',
  'trackId': '305087298',
  'cover': 'https://i1.sndcdn.com/artworks-000205293551-3mwdhg-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Nagz - Told You So bw Venge EP [2014]',
  'text': 'Available on all major shops and services',
  'type': 'web',
  'source': 'custom',
  'url': 'https://fanlink.to/WnH',
  'cover': 'img/alb_tys.png',
  'sticky': false,
  'hidden': false},
  
  {'size': '2',
  'title': 'GAME // HexxagonHD',
  'text': 'By Blind Logic // Music by Nagz',
  'type': 'web',
  'source': 'custom',
  'url': 'https://play.google.com/store/apps/details?id=eu.bl.hexxagonhd',
  'cover': 'img/hexhd.png',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'The Adjective - Sosincs Vége [2016]',
  'text': 'Function 2016 demo competition #2; Code by Gargaj, Graphics by Immortal Rat & Maxie',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=zGIOIedblM8',
  'videoId': 'zGIOIedblM8',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Nerd Fitness [2015]',
  'text': 'Function 2015 music competition #6',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nerd-fitness-2015',
  'trackId': '223653773',
  'cover': 'https://i1.sndcdn.com/artworks-000129391969-ock348-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Heartroom [2015]',
  'text': 'Pretty dark...',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-heartroom-2015',
  'trackId': '211186879',
  'cover': 'https://i1.sndcdn.com/artworks-000120818302-qwxs0p-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'The Adjective - Mégse. [2017]',
  'text': 'Function 2017 demo competition #6 // music by Nagz',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=QcSXbQol00c',
  'videoId': 'QcSXbQol00c',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'ALBUM // Nagz - Hringur EP [2007]',
  'text': 'Loud Year bw Quiet Year // A Kahvi Collective release // Microsite (defunct) by Rozmy',
  'type': 'web',
  'source': 'custom',
  'url': 'https://fanlink.to/W8u',
  'cover': 'img/alb_hrn.png',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'The Adjective - Háromnegyed Tíz [2014]',
  'text': 'Function 2014 demo competition #2; Code by Gargaj; Graphics by Immortal Rat & Kapor - nominated for best demo soundtrack at Meteoriks awards',
  'type': 'video',
  'source': 'youtube',
  'url': 'http://www.youtube.com/watch?v=Yi5PBd-VFEQ',
  'videoId': 'Yi5PBd-VFEQ',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'GAME // Sumotori Dreams',
  'text': 'by Archee // music by Nagz',
  'type': 'web',
  'source': 'custom',
  'url': 'http://www.gravitysensation.com/sumotori/',
  'cover': 'img/smtr.png',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Verticalism [2013]',
  'text': 'Just for fun',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/verticalism',
  'trackId': '124530165',
  'cover': 'https://i1.sndcdn.com/artworks-000065186801-g5xdbu-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Nagz - Alright Today [2015]',
  'text': 'Directed by Holdosi Máté, edited by Nagz',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=6rwy7SM0xT8',
  'videoId': '6rwy7SM0xT8',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'MOVIE // TimeTrap [2015]',
  'text': 'Short film directed by Holdosi Máté; foley & sound editing by Halmi Dávid',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/143734222',
  'cover': 'https://i.vimeocdn.com/video/546913997_640x360.jpg',
  'videoId': '143734222',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Nagz - Bror Dau EP [2013]',
  'text': 'Deep Dubtechno // Available on all major shops and services',
  'type': 'web',
  'source': 'custom',
  'url': 'https://fanlink.to/WnF',
  'cover': 'img/alb_bd.png',
  'sticky': false,
  'hidden': false},
  
  {'size': '2',
  'title': 'Selfmademusic - Technicolor (Nagz remix) [2012]',
  'text': 'Original from Kinema Ikon',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/selfmademusic-technicolor-nagz',
  'trackId': '42624487',
  'cover': 'https://i1.sndcdn.com/artworks-000370999263-ku3b0b-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': '1993 [2013]',
  'text': 'Mood test // Song made as Bror Dau',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=BYzRTxf5OFQ',
  'videoId': 'BYzRTxf5OFQ',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - For The Better [2012]',
  'text': 'Out on Camomille // Tons of friends remixed it later',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/camomillemusic-com-nagz-for',
  'trackId': '65649470',
  'cover': 'https://i1.sndcdn.com/artworks-000033296084-7gj0sa-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Music Television reel',
  'text': 'Animation by András Kondacs // Music by Dávid Halmi',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/128617908',
  'cover': 'https://i.vimeocdn.com/video/519819099_640x360.jpg',
  'videoId': '128617908',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Makunouchi Bento - Yuki Onna (Nagz remix)',
  'text': 'Official Makunouchi Bento remix',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/makunouchi-bento-yuki-onna',
  'trackId': '41152328',
  'cover': 'https://i1.sndcdn.com/artworks-000020641701-wmaoag-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Greenroom - Touch Me [2013]',
  'text': 'Function 2013 wild demo competition #1; Graphics by Immortal Rat & Senderkatch; Vocals by Ekrah',
  'type': 'video',
  'source': 'youtube',
  'url': 'http://www.youtube.com/watch?v=TfW69RpHZt8',
  'videoId': 'TfW69RpHZt8',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Bowtie Scopes [2011]',
  'text': 'Out on Terraform LP by Kahvi Collective',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-bowtie-scopes-2001-kahvi',
  'trackId': '26604122',
  'cover': 'https://i1.sndcdn.com/artworks-000013289446-ab0zjq-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Archee - Foo2 Island [2017]',
  'text': 'Function 2017 demo competition #1 // music by Nagz',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=W_N-lVkshnU',
  'videoId': 'W_N-lVkshnU',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Your Life Is An Orchard [2012]',
  'text': 'Sluggish techno stuff',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-your-life-is-an-orchard',
  'trackId': '74406282',
  'cover': 'https://i1.sndcdn.com/artworks-000038012026-6kh2xr-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Hello Neighbor [2010]',
  'text': 'Used later in an animation by me but it was crap',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-hello-neighbor-2010',
  'trackId': '24643057',
  'cover': 'https://i1.sndcdn.com/artworks-000012268006-jdrxek-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Bassrack Wobama - Wobservation [2011]',
  'text': 'Released on Dubstep Is Fun! Vol. 5 by Complimentary Distribution (BitLab Records)',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/bassrackwobama/bassrack-wobama-wobservation',
  'trackId': '48848278',
  'cover': 'https://i1.sndcdn.com/artworks-000024614346-k7e8yb-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Efta Solitude Station [2011]',
  'text': 'Testing EftaHigh, a free VSTi by my friend Antkai',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-efta-solitude-station',
  'trackId': '20335891',
  'cover': 'https://i1.sndcdn.com/artworks-000012268663-14ly1x-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Filmstudio Intro reel',
  'text': 'Animation by András Kondacs // Music by Dávid Halmi',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/90479477',
  'cover': 'https://i.vimeocdn.com/video/469629141_640x360.jpg',
  'videoId': '90479477',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Selena [2012]',
  'text': 'Usual Renoise stuff',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-selena-2012',
  'trackId': '44239126',
  'cover': 'https://i1.sndcdn.com/artworks-000022237626-2z8s38-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'GAME // Arcane Islands [2006]',
  'text': 'By Gamenibus // music by Nagz // Preview available on web',
  'type': 'web',
  'source': 'custom',
  'url': 'http://www.newgrounds.com/portal/view/372111',
  'cover': 'img/arcni.png',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'TimeTrap exit game',
  'text': 'Official vid // music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=ewEMBMtX_t8',
  'videoId': 'ewEMBMtX_t8',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Wanton Kiosk - Dimmer [2011]',
  'text': 'Featured on Trembl.ca',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=YwsSEDORwQs',
  'videoId': 'YwsSEDORwQs',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Chii [2012]',
  'text': 'Samples provided by Chi Recordings',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-chii-2012',
  'trackId': '56602350',
  'cover': 'https://i1.sndcdn.com/artworks-000028619591-4ax21m-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - And Now I Start Floating Away Gently [2006]',
  'text': 'Originally made for Static Picture Company',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/and-now-i-start-floating-away',
  'trackId': '134961127',
  'cover': 'https://i1.sndcdn.com/artworks-000070938580-caxakf-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'TGD - Mit Nekem Te Zordon Kárpátoknak Fenyvesekkel Vadregényes Tája 2 [2016]',
  'text': 'Function 2016 demo competition #1; Code by Jimmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=DIoVZmdjviE',
  'videoId': 'DIoVZmdjviE',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Re2alz - Understanding Rarara',
  'text': 'Made with ma\' boy Angel26',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/angeltwosix/nangzel-understanding-rarara',
  'trackId': '25051152',
  'cover': 'https://i1.sndcdn.com/avatars-000003441962-s63qls-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'The Adjective - Elégtelen [2015]',
  'text': 'Function 2015 demo competition #2; Code by Gargaj; Graphics by Immortal Rat & BetaSector',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=fRGr6pejUJo',
  'videoId': 'fRGr6pejUJo',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Bunnydrop [2011]',
  'text': 'Featured on Orange Dreams by Psylife Records',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-bunnydrop-orange-dreams',
  'trackId': '20336759',
  'cover': 'https://i1.sndcdn.com/artworks-000012268259-jmlpoq-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Teach Them To Be Quiet [2012]',
  'text': 'Usual Renoise stuff',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-teach-them-to-be-quiet',
  'trackId': '36523761',
  'cover': 'https://i1.sndcdn.com/artworks-000018315704-bjfpaq-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Eric Van Lustbader: First Daughter flash based book trailer',
  'text': 'Visuals by GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=ioUcFJ1CMo4',
  'videoId': 'ioUcFJ1CMo4',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Pastel (revisited) [2011]',
  'text': 'An old tune of mine reimagined with more modern technology',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/pastel-revisited',
  'trackId': '16964650',
  'cover': 'https://i1.sndcdn.com/artworks-000012290400-d5hdtg-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Museum Moonlights reel',
  'text': 'Animation by András Kondacs // Music by Dávid Halmi',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/131010806',
  'cover': 'https://i.vimeocdn.com/video/523090777_640x360.jpg',
  'videoId': '131010806',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Wittyboi - Take Me Back [2017]',
  'text': 'Prod. by Nagz',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/wittyboi251/take-me-back-prod-by-nagz',
  'trackId': '314668630',
  'cover': 'https://i1.sndcdn.com/artworks-000214858386-papi0l-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'PREVIEW // Para Halu - Sky City (Nagz remix)',
  'text': 'Official Para Halu remix // Get the full track on Psylife.net',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/psyliferecords/para-halu-sky-city-nagz-remix',
  'trackId': '94982899',
  'cover': 'https://i1.sndcdn.com/artworks-000049531851-ha7wpb-t500x500.jpg',
   'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Global Village, Dubai (Projection Video Mapping) [2012]',
  'text': 'By Full Screen Studio // Intro & outro music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=BQ8S236BGrY',
  'videoId': 'BQ8S236BGrY',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Alright Today SINGLE [2015]',
  'text': 'Available on all major shops and services',
  'type': 'web',
  'source': 'custom',
  'url': 'https://fanlink.to/W8j',
  'cover': 'img/alb_at.png',
  'sticky': false,
  'hidden': false},
  
  {'size': '2',
  'title': 'Wanton Kiosk - Down At Lake Phobia [2009]',
  'text': 'Trembl, maybe?',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/wantonkiosk/wanton-kiosk-down-at-lake',
  'trackId': '48845891',
  'cover': 'https://i1.sndcdn.com/artworks-000024613166-4yzkb8-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'GAME // Jewels? Fruits! Xmas',
  'text': 'By Blind Logic // Music by Nagz',
  'type': 'web',
  'source': 'custom',
  'url': 'https://play.google.com/store/apps/details?id=eu.bl.jewel.xmas',
  'cover': 'img/jfxm.png',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Good Times [2010]',
  'text': 'Usual Renoise stuff',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-good-times-2010',
  'trackId': '24711449',
  'cover': 'https://i1.sndcdn.com/artworks-000012304999-jx6w87-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Beyoncé - End of Time (Nagz Bootybouncin\' Moombahton edit) [2012]',
  'text': 'It didn\'t win',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/beyonc-end-of-time-nagz',
  'trackId': '36651547',
  'cover': 'https://i1.sndcdn.com/artworks-000018380944-j7d0bu-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Wanton Kiosk - Meteor Shelter [2008]',
  'text': 'Out on Hypocondriac LP by Apegenine records',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/wantonkiosk/wanton-kiosk-meteor-shelter',
  'trackId': '48845626',
  'cover': 'https://i1.sndcdn.com/artworks-000024613001-flrc8p-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - For The Better (Bassrack Wobama Remix) [2012]',
  'text': 'Out on Camomille // Part of For The Better Remixes LP by Nagz',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/bassrackwobama/camomillemusic-com-nagz-for',
  'trackId': '65651054',
  'cover': 'https://i1.sndcdn.com/artworks-000033296692-jl0038-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Duna TV Channel ID',
  'text': 'Animation by András Kondacs // Music by Dávid Halmi',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/98642918',
  'cover': 'https://i.vimeocdn.com/video/479436845_640x360.jpg',
  'videoId': '98642918',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Destroy Create Konfuse [2006]',
  'text': 'A gift to the DCK posse',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-destroy-create-konfuse',
  'trackId': '4336709',
  'cover': 'https://i1.sndcdn.com/artworks-000012290757-4qqf9j-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Budai Rakpart [2009]',
  'text': 'Featured in Moleman 2 documentary (molemanfilm.com)',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-budai-rakpart',
  'trackId': '3980156',
  'cover': 'https://i1.sndcdn.com/artworks-000012290891-iyibbn-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Voice Test [2013]',
  'text': 'All base samples are my recorded vocals',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-voice-test-2013',
  'trackId': '344469825',
  'cover': 'https://i1.sndcdn.com/artworks-000244743069-u7ju68-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Colorstar - Falling (Nagz remix) [2011]',
  'text': 'Official Colorstar remix',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/colorstar_falling/colorstar-falling-nagz-remix',
  'trackId': '18525386',
  'cover': 'https://i1.sndcdn.com/avatars-000002723122-yikrqr-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Mjölkmlorpf - Akrobataprospektus [2006]',
  'text': 'Auxiliary samples by Wegaz',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/mjoelkmlorpf/ep001a-akrobataprospektus',
  'trackId': '48850125',
  'cover': 'https://i1.sndcdn.com/artworks-000024615272-7f6kgw-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Aura Dust [2005]',
  'text': 'Ethno samples found on Aural // Arrangement done in Jeskola Buzz',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-aura-dust-2005',
  'trackId': '344474449',
  'cover': 'https://i1.sndcdn.com/artworks-000244747473-w6eqnl-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'LJ.com Ad (unofficial)',
  'text': 'Made in 12 hours incl. text, speech, background music, design and animation',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=1HV_y6lNKC4',
  'videoId': '1HV_y6lNKC4',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Failotron Saves The Earth [2010]',
  'text': 'Modernist chiptune made in Renoise',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-failotron-saves-the-earth',
  'trackId': '58715282',
  'cover': 'https://i1.sndcdn.com/artworks-000029721420-38mxba-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'The Art Of Listing flash intro by GreatExposures',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=Xd2gv8YPWSo',
  'videoId': 'Xd2gv8YPWSo',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'MUSICDISK // Ouch 2 [2002-2009]',
  'text': 'Feat. Amusic, Drax, Graff, Teis, Vhiiula, Jeroen Tel (WAVE), Xerxes, Willbe and Nagz // code by Gargaj',
  'type': 'web',
  'source': 'custom',
  'url': 'http://www.pouet.net/prod.php?which=6575',
  'cover': 'img/ou2.png',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Mjölkmlorpf - Toss It Out [2006]',
  'text': 'Year is uncertain // Made in Jeskola Buzz, cutting up an old chiptune song of mine',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/mjoelkmlorpf/ep002b2-toss-it-out',
  'trackId': '48851084',
  'cover': 'https://i1.sndcdn.com/artworks-000024615775-2dl8pv-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Daughter Of Time [2000]',
  'text': 'Original song is below 100kbytes // Out on SzittyaVibes, 2007',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-daughter-of-time-2000',
  'trackId': '58713795',
  'cover': 'https://i1.sndcdn.com/artworks-000029744283-ahrzjv-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Logo Animations pt. 2',
  'text': 'Animation by András Kondacs // Music by Dávid Halmi',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/83750474',
  'cover': 'https://i.vimeocdn.com/video/460330587_640x360.jpg',
  'videoId': '83750474',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Fusion-X feat. Nagz - Lenina 58 [2001]',
  'text': 'Out on Cafe Unicum (Juice Records) // Guitar sample by KcG',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/fusion-x-feat-nagz-lenina-58-2001',
  'trackId': '344470965',
  'cover': 'https://i1.sndcdn.com/artworks-000244744372-4e0tbg-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - But Does She Like Your Shoes? [2004]',
  'text': 'Ranked #4 at React2004 mp3 compo in Patras, Greece',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-but-does-she-like-your-shoes-2004',
  'trackId': '344475112',
  'cover': 'https://i1.sndcdn.com/artworks-000244748206-lkbgmq-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Haverek Közt - Mintha Szombat Volna (Pénzbedobós remix by Nagz) [2012]',
  'text': 'Official Haverek Közt remix',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/haverek-k-zt-mintha-szombat',
  'trackId': '49800672',
  'cover': 'https://i1.sndcdn.com/artworks-000025102100-v2s89d-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - This Is A joke [2015]',
  'text': 'Vaporwave shit',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/pmikqkmockdf',
  'trackId': '344469291',
  'cover': 'https://i1.sndcdn.com/artworks-000244752464-755364-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Sanagi - Rabbit Hole (Nagz remix) [2008]',
  'text': 'Official remix',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/sanagi-rabbit-hole-handheld-remix-by-nagz-2008',
  'trackId': '4336592',
  'cover': 'https://i1.sndcdn.com/artworks-000012290814-4dys34-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Coatless Loser [2014]',
  'text': 'Commemorating our legendary Brno (CZ) trip',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-coatless-loser-2014',
  'trackId': '344477524',
  'cover': 'https://i1.sndcdn.com/artworks-000244750254-l2v8tf-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Hokkaido [2010]',
  'text': 'Usual Renoise stuff',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-hokkaido-2010',
  'trackId': '24782170',
  'cover': 'https://i1.sndcdn.com/artworks-000012342729-fxt36u-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - A Short Song Made For A Girl In 1 Hour [2005]',
  'text': 'Chill, she was just a good pal ;) // Made in Jeskola Buzz',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-a-short-song-made-for-a-girl-in-1-hour-2005',
  'trackId': '344476375',
  'cover': 'https://i1.sndcdn.com/artworks-000244749199-uidsxh-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Mantra Porno - Disco Galaxy (Nagz remix) [2007]',
  'text': 'Official Mantra Porno remix // Made in Renoise',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/mantra-porno-disco-galaxy-screwed-up-by-nagz-2007',
  'trackId': '344476766',
  'cover': 'https://i1.sndcdn.com/artworks-000244749514-h32yln-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Not An Ordinary Day At All [2014]',
  'text': 'Sample test',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/nagz-not-an-ordinary-day-at-all-2014',
  'trackId': '164321887',
  'cover': 'https://i1.sndcdn.com/artworks-000088840943-842a0v-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'GAME // Animals And Fun (Africa)',
  'text': 'By Blind Logic // Music by Nagz',
  'type': 'web',
  'source': 'custom',
  'url': 'https://play.google.com/store/apps/details?id=eu.bl.africa.fun',
  'cover': 'img/anfu.png',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Big Cat Entertainment flash intro by GreatExposures',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=yF2H-IaqR_g',
  'videoId': 'yF2H-IaqR_g',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Re2alz - Notorious BIG - Dead Wrong remix [2014]',
  'text': 'With Angel26',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/re2alz/notorious-big-dead-wrong-re2alz-remix',
  'trackId': '158440701',
  'cover': 'https://i1.sndcdn.com/artworks-000085007061-m303f5-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Haujobb - E-Strange [2001]',
  'text': 'Dialogos 2001 demo competition #3',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=sGBqRB2K4-k',
  'videoId': 'sGBqRB2K4-k',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Feel The Funky Beat Yoo [2006]',
  'text': 'Out on Gama by Ronin Collective',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-feel-the-funky-beat-yoo-2005',
  'trackId': '344478050',
  'cover': 'https://i1.sndcdn.com/artworks-000244750736-jwizkt-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Dualtrax & Nagz - Neverland [2012]',
  'text': 'A hearty chiptune co-op',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/dualtrax/dualtrax-nagz-neverland',
  'trackId': '52863034',
  'cover': 'https://i1.sndcdn.com/avatars-000041341652-ykg4n5-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Where Is Maktone\'s Hair? [2004]',
  'text': '4chn .MOD',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-where-is-maktones-hair',
  'trackId': '58713280',
  'cover': 'https://i1.sndcdn.com/artworks-000029744420-dza4dg-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'GAME // Loo Game [2015]',
  'text': 'By Amorf Games // Music by Nagz',
  'type': 'web',
  'source': 'custom',
  'url': 'https://play.google.com/store/apps/details?id=com.AmorfGames.LooGame&hl=en',
  'cover': 'img/loog.png',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Valaki Kurva Gonosz [2001]',
  'text': 'Core 2001 music competition #2 // Excuse my french',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/nagz-old-tracked/valaki-kurva-gonosz-2001',
  'trackId': '134960021',
  'cover': 'https://i1.sndcdn.com/artworks-000070938050-uyt89l-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Tidal Recruit - 1der [2004]',
  'text': 'One-shot spinoff in Jeskola Buzz',
  'type': 'music',
  'source': 'soundcloud',
  'url': 'https://soundcloud.com/naaaaaagz/tidal-recruit-1der-2004-free-download',
  'trackId': '159616822',
  'cover': 'https://i1.sndcdn.com/artworks-000085785898-kwk9mc-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Sugar & Pea [2001]',
  'text': 'Early tracked acidjazz',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134957724',
  'url': 'https://soundcloud.com/nagz-old-tracked/sugar-pea-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000257028698-vyl71j-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Ericco Studio flash intro by GreatExposures',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=U1YYMHEGUq8',
  'videoId': 'U1YYMHEGUq8',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'MUSICDISK // Ouch [2000]',
  'text': 'code by Seffren',
  'type': 'web',
  'source': 'custom',
  'url': 'http://www.pouet.net/prod.php?which=683',
  'cover': 'img/ouc.png',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz & Zabutom - Cloudcones [2004]',
  'text': 'Early tracked acidjazz',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58712265',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-zabutom-cloudcones',
  'cover': 'https://i1.sndcdn.com/artworks-000029744557-gsteg8-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Farbrausch - Fr-015: La Sculpture [2001]',
  'text': 'Dialogos 2001 demo competition #2 // Contains a shortened version of my track \'Jungle Benz\'',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=DPYVjmu3NLg',
  'videoId': 'DPYVjmu3NLg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Dentist Pigeon [2000]',
  'text': 'Flag 2000 music competition #2 // 4chn .MOD',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134958127',
  'url': 'https://soundcloud.com/nagz-old-tracked/dentist-pigeon',
  'cover': 'https://i1.sndcdn.com/artworks-000070936942-zy2iwm-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Xyzawoo [2000]',
  'text': 'Progressive chiptune at its finest // Out on Rare Nnudes Chiptune Compilation vol. 1',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58718382',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-xyzawoo-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000029724229-to9vd4-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Sound & Motion by Ágota Végső',
  'text': 'Music by Nagz',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/114778451',
  'cover': 'https://i.vimeocdn.com/video/500584740_640x360.jpg',
  'videoId': '114778451',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - The Mad Professor [2003]',
  'text': 'Tremendous amount of work in FT2',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134962656',
  'url': 'https://soundcloud.com/nagz-old-tracked/the-mad-professor-2003',
  'cover': 'https://i1.sndcdn.com/artworks-000070939454-ows3lv-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - As You\'re Conjured Up [2005]',
  'text': 'Technically my last FT2 tune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58711245',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-as-youre-conjured-up-2005',
  'cover': 'https://i1.sndcdn.com/artworks-000029744767-uvecis-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'MUSICDISK // My Spanish Wankers [2004]',
  'text': 'code by Gargaj // dedicated to the spanish demomusic guys',
  'type': 'web',
  'source': 'custom',
  'url': 'http://www.pouet.net/prod.php?which=11748',
  'cover': 'img/msw.png',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Shame Of Moquiwa [2000]',
  'text': 'Mekka & Symposium 2000 music competition #5',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '344468952',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-shame-of-moquiwa-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000244742403-hu1d7b-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'ClickShops flash intro/commercial',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=c00yvGmBzAI',
  'videoId': 'c00yvGmBzAI',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - My Dog Lives On The Moon [2002]',
  'text': 'A little sine-song later gaining mainstream success thanks to Phone Losers',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '344468281',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-my-dog-lives-on-the-moon-2002',
  'cover': 'https://i1.sndcdn.com/artworks-000244741900-vuhefm-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz & Drax - Rocky North [2000]',
  'text': 'Co-op with Drax / Maniacs of Noise & Vibrants',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58711518',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-drax-rocky-north-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000029744734-u12cj6-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Plaketa Tzatziki [2002]',
  'text': 'Out on Kahvi Collective // Ranked #3 at React2004 multichannel music competition in Patras, Greece',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134960688',
  'url': 'https://soundcloud.com/nagz-old-tracked/plaketa-tzatziki-2002',
  'cover': 'https://i1.sndcdn.com/artworks-000070938347-617u6w-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - That Guy Over There Is A Planet [2010]',
  'text': 'First wonky experiment',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '344479444',
  'url': 'https://soundcloud.com/nagz-old-tracked/nagz-that-guy-over-there-is-a-planet-2010',
  'cover': 'https://i1.sndcdn.com/artworks-000244751923-xlmqvo-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Dig On Your Planet [2001]',
  'text': 'Out on Sattori EP by Ronin Collective',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134958668',
  'url': 'https://soundcloud.com/nagz-old-tracked/dig-on-your-planet-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000070937268-4qx3wn-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Peace In Rest [2002]',
  'text': 'Out on Kahvi Collective // Made in Jeskola Buzz',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134959612',
  'url': 'https://soundcloud.com/nagz-old-tracked/peace-in-rest-2002',
  'cover': 'https://i1.sndcdn.com/artworks-000070937810-0lqy60-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Brad Thor flash intro',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=-xw_QUHaL58',
  'videoId': '-xw_QUHaL58',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - 16:05, 16:10 [2001]',
  'text': 'Bus trips are unnecessarily long',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134962549',
  'url': 'https://soundcloud.com/nagz-old-tracked/16-05-16-10-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000070939388-r3laaz-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Babkova Zilina 2017 opening spot',
  'text': 'Music by Nagz',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=RRqm-3UrbCc',
  'videoId': 'RRqm-3UrbCc',
  'sticky': false,
  'hidden': false},

  {'size': '4',
  'title': 'Nagz - Chinqua [2000]',
  'text': 'Epic progressive chiptune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58717584',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-chinqua-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000029724269-djwfn2-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Llama [2011]',
  'text': '2nd After Effects giga awesomeness',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=7QxvbWfKbrU',
  'videoId': '7QxvbWfKbrU',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - May I Own You? [2000]',
  'text': 'Made for some webcompo',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58718464',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-may-i-own-you-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000029724410-p3v7lw-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Taking A Shit [2011]',
  'text': 'Made in FT2 back then in 2004, some final touches added later',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134958205',
  'url': 'https://soundcloud.com/nagz-old-tracked/taking-a-shit-2011',
  'cover': 'https://i1.sndcdn.com/artworks-000070936993-8w50ep-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'The Young Company flash intro',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=l_M9f7Lkpx0',
  'videoId': 'l_M9f7Lkpx0',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Friends With Cured Fate [2005]',
  'text': 'Progressive chiptune (under 100kB .XM)',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58716957',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-friends-with-cured-fate',
  'cover': 'https://i1.sndcdn.com/artworks-000029724718-335ucz-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Would [2002]',
  'text': 'Progressive chiptune (under 100kB .XM)',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58716381',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-would-2002',
  'cover': 'https://i1.sndcdn.com/artworks-000029724883-s22a59-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Michaud Beauty flash intro',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=7WmltWu9w30',
  'videoId': '7WmltWu9w30',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Name Me [2001]',
  'text': 'OHC chiptune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58715949',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-name-me-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000029743614-tllcg0-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Chatting With Myself From The Future [2001]',
  'text': 'Sinetune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58715684',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-chatting-with-myself-from',
  'cover': 'https://i1.sndcdn.com/artworks-000029743700-vn9nih-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - A Voy Egy Buzi [2001]',
  'text': 'Chiptune dedicated to a fellow demoscener ;)',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58715420',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-a-voy-egy-buzi-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000029743870-q82rv5-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Frog [2011]',
  'text': '1st After Effects supercoolness',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=PGWfYekEqxE',
  'videoId': 'PGWfYekEqxE',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz & Graff - Martians Are Limpid [2001]',
  'text': 'A co-op chiptune with the progtracker god, Graff',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58715067',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-graff-martians-are-limpid',
  'cover': 'https://i1.sndcdn.com/artworks-000029743942-x1h3l2-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Xylan Orb [2001]',
  'text': 'Trippy af',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58714312',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-xylan-orb-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000029744120-76yc6k-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Debbie Sabuncu Photography flash intro',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=wAioLVwd2Qw',
  'videoId': 'wAioLVwd2Qw',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Sho We\'re Than Ks [2004]',
  'text': 'Chipsize filler tune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58714254',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-sho-were-than-ks-2004',
  'cover': 'https://i1.sndcdn.com/artworks-000029744150-mq5xow-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Walkmans Unite [2005]',
  'text': 'Gamemusic style. Warning: tracked drum solo included!',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134962465',
  'url': 'https://soundcloud.com/nagz-old-tracked/walkmans-unite-2005',
  'cover': 'https://i1.sndcdn.com/artworks-000070939339-lfu8wh-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - Mellow Leader Blues [2000]',
  'text': 'Chipsize mellow tune dedicated to Vlad',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58714100',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-mellow-leader-blues-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000029744224-oirz8h-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Eastern Nature [2004]',
  'text': 'Chipsize filler tune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58713927',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-eastern-nature-2004',
  'cover': 'https://i1.sndcdn.com/artworks-000029744262-d37e3m-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'The Mínk in London 2018',
  'text': 'Music by Nagz',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/264053576',
  'cover': 'https://i.vimeocdn.com/video/693737282_640x360.jpg',
  'videoId': '264053576',
  'sticky': false,
  'hidden': false},

  {'size': '3',
  'title': 'Nagz - 99 Little Windows [2002]',
  'text': 'Sequel to my 98 Little Windows sinetune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58713574',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-99-little-windows-2002',
  'cover': 'https://i1.sndcdn.com/artworks-000029744332-uggw1y-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Danny Turner flash intro',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=zuOnKYi_2Yo',
  'videoId': 'zuOnKYi_2Yo',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Mr Y\'s Tamagotchi [2001]',
  'text': 'Chipsize irregularity',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58712160',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-mr-ys-tamagotchi-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000029744596-u3rxwb-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Darrell Redleaf flash intro',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=cBt55LCD7_4',
  'videoId': 'cBt55LCD7_4',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Jungle Benz [2000]',
  'text': 'A 12+ minutes long jazz & bass track later shortened for Haujobb',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134960561',
  'url': 'https://soundcloud.com/nagz-old-tracked/jungle-benz-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000070938293-bhn641-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'GreatExposures flash intro',
  'text': 'Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=BvUUpIfsVDw',
  'videoId': 'BvUUpIfsVDw',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Melted Sheep [2001]',
  'text': 'All 32 XM tracks full',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58712029',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-melted-sheep-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000029744620-36arg5-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Guy With No Fingers [2001]',
  'text': 'Jazzy chiptune',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58711638',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-guy-with-no-fingers-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000029744712-1phxlm-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Tran Creative flash intro',
  'text': 'By GreatExposures // Music by Dávid Halmi',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=WUYHJY7DTkw',
  'videoId': 'WUYHJY7DTkw',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'MIFÉLE ZAJ EZ? (HUN)',
  'text': 'Zenei stílusok kivesézése // Scrutinizing music genres (in hungarian)',
  'type': 'web',
  'source': 'custom',
  'url': 'http://www.facebook.com/mifelezajez/',
  'cover': 'img/mze.png',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Skin After Flash [2000]',
  'text': 'Chipsize IDM',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58714506',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-skin-after-flash-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000029744088-fgspq1-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Tipikus Arnold [2003]',
  'text': 'On the verge of chiptune and progressive randomness',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58715828',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-tipikus-arnold-2003',
  'cover': 'https://i1.sndcdn.com/artworks-000029743649-dhgfks-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Baldexare Derrixsus [1999]',
  'text': 'This is how my old stuff sounded like',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134961629',
  'url': 'https://soundcloud.com/nagz-old-tracked/baldexare-derrixsus-1999',
  'cover': 'https://i1.sndcdn.com/artworks-000070938856-okvia3-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Userless Hero #5 by Demeter Lóránt [2014]',
  'text': 'title song by Nagz',
  'type': 'video',
  'source': 'youtube',
  'url': 'https://www.youtube.com/watch?v=UDbrVNUYWlk',
  'videoId': 'UDbrVNUYWlk',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Goodnight Kiss [2000]',
  'text': 'Ambient for all you lovers out there',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134961100',
  'url': 'https://soundcloud.com/nagz-old-tracked/goodnight-kiss-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000070938565-m6auf5-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Toldi Artmozi Spot by Ágota Végső and Zsuzsi Bányai',
  'text': 'Music by Nagz',
  'type': 'video',
  'source': 'vimeo',
  'url': 'https://vimeo.com/98525964',
  'cover': 'https://i.vimeocdn.com/video/598476311_640x360.jpg',
  'videoId': '98525964',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'Nagz - Pipi [2000]',
  'text': 'Since y\'all like it so much ;)',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '58711765',
  'url': 'https://soundcloud.com/nagz-chiptune/nagz-pipi-2000',
  'cover': 'https://i1.sndcdn.com/artworks-000029744666-asaay4-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'AAAAAAAAAA (HUN)',
  'text': '4,025 tweet-nyi helyzetjelentés // Status report in 4,025 tweets (in hungarian)',
  'type': 'web',
  'source': 'custom',
  'url': 'https://twitter.com/tizdarababetu',
  'cover': 'img/aaaaa.png',
  'sticky': false,
  'hidden': false},

  {'size': '2',
  'title': 'Nagz - Work Once A Year [2001]',
  'text': 'Ho ho ho!',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '134959076',
  'url': 'https://soundcloud.com/nagz-old-tracked/work-once-a-year-2001',
  'cover': 'https://i1.sndcdn.com/artworks-000070937560-n6gk9d-t500x500.jpg',
  'sticky': false,
  'hidden': false},

  {'size': '1',
  'title': 'MUSICONPURPOSE.NET reel (2002-2010)',
  'text': 'Selection of works for my reference page',
  'type': 'music',
  'source': 'soundcloud',
  'trackId': '48846419',
  'url': 'https://soundcloud.com/musiconpurpose/musiconpurpose-net-demoreel',
  'cover': 'https://i1.sndcdn.com/artworks-000024613397-z55a9z-t500x500.jpg',
  'sticky': false,
  'hidden': false},
]
